// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-dashboard-auth-success-js": () => import("./../../../src/pages/dashboard-auth-success.js" /* webpackChunkName: "component---src-pages-dashboard-auth-success-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lifetime-download-js": () => import("./../../../src/pages/lifetime_download.js" /* webpackChunkName: "component---src-pages-lifetime-download-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-roicalculator-js": () => import("./../../../src/pages/roicalculator.js" /* webpackChunkName: "component---src-pages-roicalculator-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-teams-js": () => import("./../../../src/pages/teams.js" /* webpackChunkName: "component---src-pages-teams-js" */),
  "component---src-templates-markdown-js": () => import("./../../../src/templates/markdown.js" /* webpackChunkName: "component---src-templates-markdown-js" */),
  "component---src-templates-updates-js": () => import("./../../../src/templates/updates.js" /* webpackChunkName: "component---src-templates-updates-js" */)
}

